import React, { useEffect, useRef, useState } from 'react'
import '../newWebsite/NewCSS/Register.scss'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import Header from './header'

import google from '../../images/google.png'
import linkedin from '../../images/linkedin.png'
import web_signup from '../../images/auths/web_signup.png'
import mobile_signup from '../../images/auths/mobile_signup.png'
import success_gif from '../../images/success.gif'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import request, { NodeURL, re_captcha_sitekey } from '../../api/api'
// import io from 'socket.io-client'
import { useLocation, useHistory } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import { getSocketIo } from '../../api/socket'
// const socket = io(NodeURL);

const schema1 = yup.object().shape({
   firstName: yup.string().required('This is required field'),
   lastName: yup.string().required('This is required field'),
   email: yup
      .string()
      .email('Please enter a valid email')
      .required('This is required field')
      .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/, 'Invalid email format')
})
const schema2 = yup.object().shape({
   companyName: yup.string().required('This is required field'),
   phoneNumber: yup
      .string()
      .required('This is a required field')
      .matches(/^[0-9]{11}$/, 'Enter a valid 11-digit phone number'),
   domain: yup
      .string()
      .required('Please enter a Domain Name')
      .min(6, 'Your Domain Name must be more than 4 characters')
      .test('no-special-characters', 'No special characters allowed in the domain', value => {
         const specialCharactersRegex = /[!@#$%^&*(),.?":{}|<>]/
         return !specialCharactersRegex.test(value)
      })
})
const schema3 = yup.object().shape({
   userName: yup.string().required('This is required field'),
   password: yup
      .string()
      .required('This is required field')
      .min(6, 'Password must have minimum 6 characters'),
   confirmPassword: yup
      .string()
      .required('This is required field')
      .oneOf([yup.ref('password'), null], `Passwords didn't match`)
})
// GET Shift New Register Page

const RegisterTab = () => {
   const tabs = [
      {
         title: 'Web',
         description:
            'Revolutionise staff scheduling with an intuitive shift management tool. Effortlessly create, edit, and view shifts online, integrating timesheets, compliance, recruitment tracking, and more for optimised workforce management.',
         image: web_signup
      },
      {
         title: 'Mobile App',
         description:
            "Empower your team with our feature-rich employee app. Manage shifts, record attendance, and stay compliant with ease. It's your one-stop solution for shift swaps, availability management, and timely reminders.",
         image: mobile_signup
      }
   ]

   const [activeTab, setActiveTab] = useState(1)

   const description = tabs[activeTab - 1].description
   const image = tabs[activeTab - 1].image

   return (
      <div className="register-tab">
         <div className="tab-btns">
            {tabs.map((tab, idx) => (
               <button type="button" key={idx} className={activeTab === idx + 1 ? `tab-btn tab-active` : `tab-btn`} onClick={() => setActiveTab(idx + 1)}>
                  {tab.title}
               </button>
            ))}
         </div>
         <div className="tab-content">
            <p className="tab-description">{description}</p>
            <div className="tab-image">
               <img src={image} alt="Register" />
            </div>
         </div>
      </div>
   )
}

const totalSteps = 3

const registerSchema = [schema1, schema2, schema3]
const MultiStepRegister = () => {
   const [currentStep, setCurrentStep] = useState(1)
   const {
      register,
      handleSubmit,
      formState: { errors },
      trigger
   } = useForm({ resolver: yupResolver(registerSchema[currentStep - 1]) })
   const [totalProgress, setTotalProgress] = useState(0)
   const [showPassword, setShowPassword] = useState(false)
   const [showConfirmPassword, setShowConfirmPassword] = useState(false)
   const [captchaToken, setCaptchaToken] = useState(null)
   const captchaRef = useRef(null)

   const [invalid, setInvalid] = useState(false)
   const [message, setMessage] = useState('')
   const [success, setSuccess] = useState(false)
   const [isLoading,setIsLoading] = useState(false)

   const [authWindow, setAuthWindow] = useState(null)

   const domLocation = useLocation()
   const domHistory = useHistory()

   useEffect(() => {
      let timeout
      if (domLocation.pathname === '/thank-you') {
         setSuccess(() => true)
         setCurrentStep(() => 3)
         timeout = setTimeout(() => {
            domHistory.replace('/signin')
            setSuccess(() => false)
            setCurrentStep(() => 1)
         }, 5000)
      }
      return () => {
         clearTimeout(timeout)
      }
   }, [domLocation])

   useEffect(() => {
      setTotalProgress(() => (currentStep / totalSteps) * 100)
   }, [currentStep])

   const nextStep = () => {
      setCurrentStep(prevStep => prevStep + 1)
   }

   const prevStep = () => {
      setCurrentStep(prevStep => prevStep - 1)
   }
   const handleNextStep = async fieldNames => {
      nextStep()
      // try {
      //   const isValid = await trigger(fieldNames,{shouldFocus:true});

      //   if (isValid) {
      //     nextStep();
      //   }
      // } catch (error) {
      //   console.error(error);
      // }
   }

   const handlePassword = () => {
      setShowPassword(prevShowPassword => !prevShowPassword)
   }

   const handleConfirmPassword = () => {
      setShowConfirmPassword(prevShowConfirmPassword => !prevShowConfirmPassword)
   }

   const checkAuthWindow = () => {
      const check = setInterval(() => {
         if (!authWindow || authWindow.closed || authWindow.closed === undefined) {
            clearInterval(check)
         }
      }, 1000)
   }
   const openAuthWindow = provider => {
      let socket = getSocketIo()
      let socketId = (socket && socket.id) || ''
      const width = 600,
         height = 600
      const left = window.innerWidth / 2 - width / 2
      const top = window.innerHeight / 2 - height / 2
      const url = `${NodeURL}/site/${provider}/auth?socketId=${socketId}&logintype=user`
      setAuthWindow(
         window.open(
            url,
            '',
            `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`
         )
      )
   }
   const socialLogin = provider => e => {
      e.preventDefault()
      openAuthWindow(provider)
      checkAuthWindow()
   }

   const handleOnFormSubmit = async data => {
      const registerform = {
         email: data.email,
         password: data.password,
         username: data.userName,
         subdomain: data.domain,
         confirm_password: data.confirmPassword,
         name: data.firstName,
         surname: data.lastName,
         phone: {
            code: '44',
            number: data.phoneNumber,
            dailcountry: 'gb'
         },
         address: {
            line1: '',
            line2: '',
            city: '',
            state: '',
            country: '',
            zipcode: '',
            formatted_address: '',
            lat: '38.685516',
            lng: '-101.073324'
         },
         company_name: data.companyName,
         company_phone: {
            code: '44',
            number: '',
            dailcountry: 'gb'
         },
         company_email: '',
         company_logo: '',
         status: 1,
         isverified: 1
      }
      setIsLoading(true)
      // Execute the CAPTCHA validation when form is submitted
      const recaptchaInstance = captchaRef.current
      let token
      if (recaptchaInstance) {
         token = await recaptchaInstance.execute()
         let isValid = await validateToken(token)
         if (!isValid) {
            setInvalid(true)
            setMessage('Failed to verify reCAPTCHA. Please refresh the page and try again.')
            return
         }
      }
      await request({ url: '/register/agency', method: 'POST', data: registerform })
      .then(res => {
         if (res.status === 1) {
            setInvalid(false)
            setSuccess(true)
            domHistory.replace('/thank-you')
         } else if (res.status === 0) {
            setInvalid(true)
            setMessage(res.response)
         }
      })
      .finally(()=>{
        setIsLoading(false)
      })
   }

   const validateToken = async token => {
      try {
         let respdata = await request({ url: '/site/verify-captcha', method: 'POST', data: { token } })
         // console.log(respdata,'respdata')
         return Boolean(respdata && respdata.success)
      } catch (error) {
         // console.log(error,'respdata')
         return false
      }
   }

   return (
      <div className="register-modal">
         {/* <div className="progress-box">
        <progress value={totalProgress} max="100" />
      </div> */}

         {currentStep === 1 && (
            <form className="new-form" onSubmit={handleSubmit(handleNextStep)} noValidate>
               <h5 className="progress-box">
                  Register <span>Your Details</span>
               </h5>
               <div className="progress">
                  <div className="progress-bar" role="progressbar" style={{ width: '25%' }} aria-valuenow="25" aria-valuemax="100"></div>
               </div>
               <div className="form-group">
                  <label className="form-label">First Name</label>
                  <input {...register('firstName')} type="text" className="form-control" />
                  {errors.firstName && <span className="form-error">{errors.firstName.message}</span>}
               </div>
               <div className="form-group">
                  <label className="form-label">Last Name</label>
                  <input {...register('lastName')} type="text" className="form-control" />
                  {errors.lastName && <span className="form-error">{errors.lastName.message}</span>}
               </div>
               <div className="form-group">
                  <label className="form-label">Email Address</label>
                  <input {...register('email')} type="email" className="form-control" />
                  {errors.email && <span className="form-error">{errors.email.message}</span>}
               </div>
               <div className="step-btns">
                  <button type="submit" className="step-btn step-nxt" /* onClick={() => handleNextStep(['firstName', 'lastName', 'email'])} */>
                     Next
                  </button>
               </div>
               <p className="reg-here">
                  Already a User?<Link to="/signin">Signin</Link>
               </p>
               <p className="acc-quc">
                  <b>or access quickly</b>
               </p>
               <div className="login-through">
                  <button type="button" onClick={socialLogin('google')}>
                     <img src={google} alt="Google" /> Google
                  </button>
                  <button type="button" onClick={socialLogin('linkedin')}>
                     <img src={linkedin} alt="LinkedIn" /> LinkedIn
                  </button>
               </div>
            </form>
         )}

         {currentStep === 2 && (
            <form className="new-form" onSubmit={handleSubmit(handleNextStep)} noValidate>
               <h5 className="progress-box">
                  Register <span>Your Business Details</span>
               </h5>
               <div className="progress">
                  <div className="progress-bar" role="progressbar" style={{ width: '50%' }} aria-valuenow="50" aria-valuemax="100"></div>
               </div>
               <div className="form-group">
                  <label className="form-label">Company Name</label>
                  <input {...register('companyName')} type="text" className="form-control" />
                  {errors.companyName && <span className="form-error">{errors.companyName.message}</span>}
               </div>
               <div className="form-group">
                  <label className="form-label">Phone number</label>
                  <input {...register('phoneNumber')} type="tel" className="form-control" />
                  {errors.phoneNumber && <span className="form-error">{errors.phoneNumber.message}</span>}
               </div>
               <div className="form-group domain-group">
                  <label className="form-label">Choose a Domain</label>
                  <span className="pre-domain">.getshifts.co.uk</span>
                  <input {...register('domain')} type="tel" className="form-control" />
                  {errors.domain && <span className="form-error">{errors.domain.message}</span>}
               </div>
               <div className="step-btns">
                  <button type="button" className="step-btn step-prev" onClick={prevStep}>
                     Previous
                  </button>
                  <button type="submit" className="step-btn step-nxt" /* onClick={() => handleNextStep(['companyName', 'phoneNumber', 'domain']) } */>
                     Next
                  </button>
               </div>
            </form>
         )}

         {currentStep === 3 &&
            (!success ? (
               <form className="new-form" onSubmit={handleSubmit(handleOnFormSubmit)} noValidate>
                  <h5 className="progress-box">
                     Register <span>Create Your Login Details</span>
                  </h5>
                  <div className="progress">
                     <div className="progress-bar" role="progressbar" style={{ width: '80%' }} aria-valuenow="80" aria-valuemax="100"></div>
                  </div>
                  <div className="form-group">
                     <label className="form-label">
                        User Name <span>(Choose a user name)</span>
                     </label>
                     <input {...register('userName')} type="text" className="form-control" />
                     {errors.userName && <span className="form-error">{errors.userName.message}</span>}
                  </div>
                  <div className="form-group">
                     <label className="form-label">
                        Password <span>(Set a password)</span>
                     </label>
                     <input {...register('password')} type={showPassword ? 'text' : 'password'} className="form-control" />
                     <button type="button" className="toggle-pass" onClick={handlePassword}>
                        {showPassword ? 'Hide' : 'Show'}
                     </button>
                     {errors.password && <span className="form-error">{errors.password.message}</span>}
                  </div>
                  <div className="form-group">
                     <label className="form-label">Confirm Password</label>
                     <input {...register('confirmPassword')} type={showConfirmPassword ? 'text' : 'password'} className="form-control" />
                     <button type="button" className="toggle-pass" onClick={handleConfirmPassword}>
                        {showConfirmPassword ? 'Hide' : 'Show'}
                     </button>
                     {errors.confirmPassword && <span className="form-error">{errors.confirmPassword.message}</span>}
                  </div>
                  {invalid && (
                     <p className="invalid-data alert alert-danger" role="alert">
                        {message}{' '}
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => setInvalid(false)}>
                           &times;
                        </button>
                     </p>
                  )}
                  {/* {success && <p className='valid-data alert alert-success' role='alert'>Thank you for registering for the free trial, check your email for instructions. <Link to="/signin" className="sign-btn">Signin</Link><button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={()=>setSuccess(false)}>&times;</button></p>} */}
                  <ReCAPTCHA sitekey={re_captcha_sitekey} size="invisible" ref={captchaRef} />
                  <div className="step-btns">
                     <button type="button" className="step-btn step-prev" onClick={prevStep}>
                        Previous
                     </button>
                     <button type="submit" disabled={isLoading} className="step-btn step-nxt">
                        { isLoading ? "Loading..." : 'Signup' }
                     </button>
                  </div>
               </form>
            ) : (
               <>
                  <h5 className="progress-box">
                     Register <span>Successfully</span>
                  </h5>
                  <div className="progress">
                     <div className="progress-bar" role="progressbar" style={{ width: '100%' }} aria-valuenow="100" aria-valuemax="100"></div>
                  </div>
                  <div className="thank-register">
                     <span>
                        <img src={success_gif} alt="Success" />
                     </span>{' '}
                     Thank you for signing up for the free trial, check your email for instructions.{' '}
                     <Link to="/signin" className="sign-btn">
                        Signin
                     </Link>
                  </div>
               </>
            ))}
      </div>
   )
}

const GetShiftRegister = () => {
   return (
      <div>
         <Header id="hedspace"></Header>
         <div className="register-container">
            <div className="container">
               <RegisterTab />
               <MultiStepRegister />
            </div>
         </div>
      </div>
   )
}

export default GetShiftRegister
